/**======================================================================
=========================================================================
Template Name: Able Pro - Bootstrap Admin Template
Author: Phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css
=========================================================================
=================================================================================== */

// main framework
@import 'settings/color-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'settings/bootstrap-variables';
@import 'settings/theme-variables';

:root {
  --pc-header-background: rgba(var(--bs-white-rgb), 0.7);
}

section {
  padding: 100px 0;
}

.title {
  margin-bottom: 50px;

  h2 {
    font-weight: 600;
  }

  h5 {
    font-weight: 500;
  }
}

.landing-page {
  overflow-x: hidden;

  @media (min-width: 1600px) {
    .container {
      max-width: 1200px;
    }
  }
}

.navbar {
  position: fixed;
  padding: 16px 0;
  width: 100%;
  z-index: 1030;
  backdrop-filter: blur(7px);
  background-color: var(--pc-header-background);

  &.top-nav-collapse {
    box-shadow: 0px 12px 24px rgba(27, 46, 94, 0.05);
  }

  &.default {
    top: 0;
    box-shadow: 0px 0px 24px rgba(27, 46, 94, 0.05);
  }
}

.component-page {
  .navbar {
    z-index: 1025;
  }

  .list-group {
    list-style-type: none;
  }

  .component-block {
    padding: 100px 0 40px;
  }

  .component-live-link {
    a {
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .component-list-card {
    box-shadow: var(--pc-sidebar-shadow);
    width: 100%;

    .card-body {
      overflow-y: auto;
      height: calc(100vh - 235px);
    }

    .list-group-item {
      position: relative;
      border: none;
      font-weight: 500;
      padding: 12px var(--bs-list-group-item-padding-x);

      &.list-group-item-action {
        color: var(--pc-sidebar-color);
        border-radius: var(--bs-border-radius);
        width: calc(100% - 20px);
        margin: 2px 10px;

        &:hover {
          color: var(--pc-heading-color);
          background-color: rgba(#{var(--pc-sidebar-color-rgb)}, 0.1);
        }

        &.active {
          color: var(--bs-primary);
          background-color: rgba(#{var(--bs-primary-rgb)}, 0.1);
        }
      }
    }
  }

  .component-offcanvas {
    @media (min-width: 1200px) {
      position: sticky;
      top: 100px;
      z-index: 1020;
    }
    @media (max-width: 1199.98px) {
      width: 300px;
    }

    &.show {
      .card {
        border: none;
      }

      .component-list-card {
        margin-bottom: 0;

        .card-body {
          height: calc(100vh - 150px);
        }
      }
    }
  }

  .footer {
    padding-top: 60px;

    .footer-top {
      padding: 60px 0;
      margin-top: 60px;
      border-top: 1px solid var(--bs-border-color);
      border-bottom: 1px solid var(--bs-border-color);
    }

    @media (max-width: 991.98px) {
      padding-top: 40px;

      .footer-top {
        padding: 40px 0;
        margin-top: 40px;
      }
    }

    .footer-bottom {
      padding: 22px 0;
    }

    .footer-link {
      a {
        margin: 14px 0;
        display: block;
        transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
        color: var(--bs-primary);

        @media (max-width: 575.98px) {
          margin: 4px 0;
        }

        &:not(:hover) {
          color: var(--bs-gray-700);
          opacity: 0.9;
        }
      }

      li {
        &:last-child {
          a {
            margin-bottom: 0;
          }
        }
      }
    }

    .footer-sos-link {
      a {
        transition: all 0.08s cubic-bezier(0.37, 0.24, 0.53, 0.99);
        color: var(--bs-primary);

        &:not(:hover) {
          color: var(--bs-body-color);
          opacity: 0.9;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  section {
    padding: 40px 0;
  }
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */

.pc-component {
  position: relative;

  &.card-body {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  }
}

/* Modal */

.pc-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pc-modal-opened {
  overflow: hidden !important;

  .pc-modal {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.pc-component {
  .pc-modal-content {
    margin: 25px -25px -25px;
    padding: 45px 25px 25px;
  }

  &.card-body {
    .pc-modal-content {
      margin: 25px -25px -25px;
      padding: 45px 25px 25px;
    }
  }
}
.card-border-outside {
  .pc-modal-content {
    margin-inline: 0;
    margin-bottom: 0;
  }
}

.pc-modal-content {
  max-width: 100vw;
  overflow: auto;
  position: relative;
  border-top: 1px solid var(--bs-border-color);

  > pre {
    overflow: hidden;
    width: 100%;
    margin-bottom: 0;
    margin-top: 20px;

    .hljs {
      &::-webkit-scrollbar {
        height: 4px;
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: darken($body-bg, 05%);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: darken($body-bg, 25%);
      }
    }

    > code {
      padding: 0;
      background: none;
      font-size: 16px;
    }
  }
}

.md-pc-modal-copy,
.pc-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: absolute;
  top: 15px;
  border-radius: 20px;
  padding: 0;
  width: 40px;
  height: 40px;
  color: var(--bs-body-color);
  z-index: 1;
  white-space: nowrap;

  &:hover {
    color: var(--bs-primary);
    background: var(--bs-primary-light);
  }
}

.pc-collapse {
  right: 25px;
}

.md-pc-modal-copy {
  right: 75px;

  &.copied::before {
    content: 'Copied!.';
    position: absolute;
    display: block;
    right: 100%;
    margin-right: 10px;
    font-size: 14px;
    color: var(--bs-success);
    background: rgba(var(--bs-success-rgb), 0.1);
    line-height: 24px;
    height: 24px;
    border-radius: var(--bs-border-radius);
    padding: 0 6px;
    top: 50%;
    margin-top: -12px;
  }
}

.pc-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;

  &:hover {
    color: #fff;
    opacity: 0.9;
  }
}

/* Code */

.cui-bottom-spacer {
  height: 12rem;
}

/* editor style for model */

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #6e7073;
  background: #fafafa;
}

.hljs-comment,
.hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #a626a4;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e45649;
}

.hljs-literal {
  color: #0184bb;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #50a14f;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #c18401;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #986801;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #4078f2;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}
